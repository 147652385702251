import React from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ROUTES } from "../App";
export default function Footer() {
  const navigate = useNavigate();
  const styles = {
    stickToBottom: {
      marginTop: "auto",
    },
    footerItems: {
      fontWeight: "light",
      marginRight: { lg: "1rem", xs: 0 },
      marginBottom: 0,
      textAlign: "center",
      color: "#FFFFFF",
      cursor: "pointer",
    },
  };
  return (
    <>
      <CssBaseline />
      <Box
        style={styles.stickToBottom}
        px={{ lg: 12, sm: 4 }}
        py={3}
        sx={{
          bgcolor: "secondary.main",
          color: "white",
          display: "flex",
          flexDirection: { lg: "row", xs: "column" },
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "20px 20px 0px 0px",
        }}
      >
        <Box sx={{ fontSize: 12, mb: { xs: 4, lg: 0 } }}>
          <Typography paragraph={true} sx={styles.footerItems}>
            @{new Date().getFullYear()} BKMTL. All Rights Reserved. <br />{" "}
            {/* Powered by BKMTL. */}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column" },
          }}
        >
          <Typography
            paragraph={true}
            sx={styles.footerItems}
            onClick={() => navigate("/support")}
          >
            FAQS
          </Typography>
          <Typography
            paragraph={true}
            sx={styles.footerItems}
            onClick={() => navigate(ROUTES.userGuide)}
          >
            User Guide
          </Typography>
          <Typography
            paragraph={true}
            sx={styles.footerItems}
            onClick={() => navigate(ROUTES.termsConditions)}
          >
            Terms & Conditions
          </Typography>
          <Typography
            paragraph={true}
            sx={styles.footerItems}
            onClick={() => navigate(ROUTES.privacyPolicy)}
          >
            Privacy Notice
          </Typography>
          <Typography
            paragraph={true}
            sx={styles.footerItems}
            onClick={() => navigate(ROUTES.cookiePolicy)}
          >
            Cookie Policy
          </Typography>
        </Box>
      </Box>
    </>
  );
}
