import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { ArrowForward } from "@mui/icons-material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useMemo } from "react";

// TYPES
import { IPage } from "../../interface/Common";
import { useFetchQuery } from "../../customHooks/reactQuery/useFetchQuery";
import { CircularProgress } from "@mui/material";

const pages: IPage[] = [
  { name: "My Dashboard", path: "/dashboard", testId: "nav-dashboard" },
  { name: "Contact Us", path: "/contact-us", testId: "nav-contact-us" },
  {
    name: "Transactions History",
    path: "/transactions",
    testId: "nav-transactions-history",
  },
];
const settings: IPage[] = [
  { name: "My Profile", path: "/profile", testId: "nav-profile-id" },
  {
    name: "Company Details",
    path: "/company-profile",
    testId: "nav-company-id",
  },
  { name: "Transaction Inquiry", path: "/reserve", testId: "nav-reserve" },
  { name: "Bank Accounts", path: "/accounts", testId: "nav-bank-id" },
  {
    name: "Security settings",
    path: "/security-settings",
    testId: "nav-security-settings",
  },
  { name: "Logout", path: "/sign-in", testId: "nav-logout-id" },
];

const NavbarMain = () => {
  const [, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();
  const authCtx = React.useContext(AuthContext);

  const { data, isLoading, isError, isSuccess } = useFetchQuery(
    "user-profile",
    "user-profile",
    true,
    false,
    authCtx.companyDetails.tokenAccess
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const initials = useMemo(() => {
    if (isSuccess) {
      const profilePicture = data?.profile_picture; // Access the profile_picture property from the data object
      if (profilePicture) {
        return profilePicture;
      }
    }

    return "T";
  }, [data, isSuccess, isError, isLoading]);

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      sx={{
        p: 1,
      }}
    >
      <Box paddingX={4}>
        <Toolbar disableGutters>
          <Typography
            variant="h5"
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              display: { xs: "none", md: "flex" },
            }}
            onClick={() => navigate("/dashboard")}
          >
            <Typography
              variant="inherit"
              display="inline"
              color="primary.main"
              sx={{ fontWeight: "bold" }}
            >
              BKMTL
            </Typography>{" "}
          </Typography>

          <Typography
            variant="h5"
            sx={{
              flexGrow: 1,
              cursor: "pointer",
              display: { xs: "flex", md: "none" },
            }}
            onClick={() => navigate("/")}
          >
            <Typography
              variant="inherit"
              display="inline"
              color="primary.main"
              sx={{ fontWeight: "bold" }}
            >
              Bonto
            </Typography>{" "}
          </Typography>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            {pages.map((page: IPage) => (
              <MenuItem
                data-testid={page?.testId}
                key={page.name}
                onClick={() => {
                  setAnchorElNav(null);
                  return navigate(page.path);
                }}
              >
                <Typography textAlign="center" color="primary.dark">
                  {page.name}
                </Typography>
              </MenuItem>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, marginLeft: 2 }}>
            <Tooltip title="Open settings">
              <Button
                onClick={handleOpenUserMenu}
                sx={{ p: 0, color: "white" }}
                data-testid="setting-toggle-btn"
              >
                {isLoading ? (
                  <CircularProgress thickness={2} />
                ) : (
                  <Avatar alt="User profile" src={initials}></Avatar>
                )}

                <KeyboardArrowDownOutlinedIcon color="secondary" />
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: "45px", borderRadius: "17px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              disableScrollLock={true}
            >
              {settings.map((setting: IPage) => (
                <Box
                  sx={{ px: 2, py: 0, m: 0 }}
                  key={setting.name}
                  onClick={() => {
                    setAnchorElUser(null);
                    if (setting.name === "Logout") {
                      authCtx.logout();
                    } else {
                      navigate(setting.path);
                    }
                  }}
                >
                  <MenuItem
                    data-testid={setting.testId}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      textAlign="center"
                      fontWeight={900}
                      fontSize={12}
                      sx={{ mr: 2 }}
                    >
                      {setting.name}
                    </Typography>
                    <ArrowForward
                      fontSize="small"
                      color="primary"
                      sx={{ width: "14px", height: "auto" }}
                    />
                  </MenuItem>
                  <Divider sx={{ m: 0 }} />
                </Box>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default NavbarMain;
