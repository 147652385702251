import React, { useCallback, useContext, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Hidden, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Drawer from "../Drawer";
import Menu from "@mui/material/Menu";
import AuthContext from "../../context/AuthContext";
import { mfaEnabled, mfaLoggedIn } from "../../utils/userFunctions";

const useStyles = makeStyles<void, "text">()((_theme, _params, classes) => ({
  text: {
    [_theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  MuiToolbar: {
    [_theme.breakpoints.up("lg")]: {},
  },
}));

const pages = [
  { name: "Home", check: true, link: "/" },
  { name: "Contact Us", check: false, link: "/contact-us" },
  { name: "Sign In", check: true, link: "/sign-in" },
];

export default function Navbar() {
  const ctxAuth = useContext(AuthContext);
  let navigate = useNavigate();
  const { classes } = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const changeUp = useCallback((data: boolean) => {
    setOpenDrawer(data);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Drawer status={openDrawer} changeStatus={changeUp} />
      <Box>
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          sx={{
            p: 1,
          }}
        >
          <Toolbar className={classes.MuiToolbar}>
            <Box sx={{ display: { xs: "flex", lg: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", lg: "none" },
                }}
                disableScrollLock={true}
              >
                {pages.map((page) =>
                  page.check && ctxAuth.userDetails.isLoggedIn ? null : (
                    <MenuItem
                      key={page.name}
                      onClick={() => navigate(page.link)}
                    >
                      <Typography textAlign="center" color="primary.dark">
                        {page.name}
                      </Typography>
                    </MenuItem>
                  )
                )}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              sx={{ flexGrow: 1, cursor: "pointer" }}
              onClick={ctxAuth.homeNavigation}
            >
              <Typography
                variant="inherit"
                display="inline"
                color="primary.main"
                sx={{ fontWeight: "bold" }}
              >
                BKMTL
              </Typography>
              {"  "}
            </Typography>

            <Hidden only={["xs", "sm", "md"]}>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {pages.map((page) =>
                  page.check && ctxAuth.userDetails.isLoggedIn ? null : (
                    <Button
                      key={page.name}
                      onClick={() => navigate(page.link)}
                      sx={{
                        color: "secondary.main",
                        fontWeight: "medium",
                        mr: 2,
                      }}
                    >
                      {page.name}
                    </Button>
                  )
                )}
              </Box>
              {ctxAuth.userDetails.isLoggedIn &&
              mfaEnabled(ctxAuth.userDetails) &&
              !mfaLoggedIn(ctxAuth.userDetails) ? (
                <Button
                  sx={{
                    color: "white",
                    backgroundColor: "secondary.main",
                    minWidth: "153px",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                  onClick={() => navigate("/validate-otp")}
                >
                  Complete Authentication process
                </Button>
              ) : ctxAuth.userDetails.isLoggedIn ? (
                <Button
                  sx={{
                    color: "white",
                    backgroundColor: "secondary.main",
                    minWidth: "153px",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                  onClick={ctxAuth.homeNavigation}
                >
                  Complete KYC process
                </Button>
              ) : (
                <Button
                  sx={{
                    color: "white",
                    backgroundColor: "secondary.main",
                    minWidth: "153px",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                  onClick={() => navigate("/sign-up")}
                >
                  Get Started
                </Button>
              )}
            </Hidden>
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}
