import { useContext, useRef, lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Company from "./pages/authentication/Company";
import PrivateRoutes from "./compnents/routes/PrivateRoute";

// cookies and google analytics libraries
import { Cookies, getCookieConsentValue } from "react-cookie-consent";
import ReactGA from "react-ga4";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "./context/AuthContext";
import PageLayout from "./pages/Templates/PageLayout";
import EmailVerfication from "./pages/EmailVerfication";
import OpenRoutes from "./compnents/routes/OpenRoutes";
import ValidateOtp from "./pages/authentication/2fa/ValidateOtp";
import TwoFactorAuth from "./pages/authentication/2fa/TwoFactorAuth";
import TwoFactorAuthApp from "./pages/authentication/2fa/TwoFactorAuthApp";
import TwoFactorAuthEmail from "./pages/authentication/2fa/TwoFactorAuthEmail";
import CompanySetUpRoutes from "./compnents/routes/CompanySetUpRoute";
import CompanyCreation from "./compnents/routes/KYC/CompanyCreation";
import SignDocs from "./compnents/routes/KYC/SignDoc";

// component
const CookieBannerConsent = lazy(
  () => import("./compnents/CookieBannerConsent")
);

// GENERAL PAGES
const FAQS = lazy(() => import("./pages/FAQ"));
const Contact = lazy(() => import("./pages/ContactUs"));
const LandingPage = lazy(() => import("./pages/Landing"));

const RoutesController = lazy(
  () => import("./compnents/routes/RoutesContorller")
);
const Accounts = lazy(() => import("./pages/Accounts"));
const Verfication = lazy(() => import("./pages/Verification"));
const MainUserVerfication = lazy(() => import("./pages/MainUserVerification"));
const CompanyBannedVerfication = lazy(
  () => import("./pages/CompanyBannedVerification")
);
const PaymentPage = lazy(() => import("./pages/main/ExchangePage"));
const SuggestRate = lazy(() => import("./pages/main/SuggetsRate"));
const Profile = lazy(() => import("./pages/Profile"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const UserGuide = lazy(() => import("./pages/UserGuide"));
const CR12Guide = lazy(() => import("./pages/CR12Guide"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));

// AUTHENTICATION
const ResetPassword = lazy(
  () => import("./pages/authentication/ResetPassword")
);
const SignUp = lazy(() => import("./pages/Signup"));
const SignIn = lazy(() => import("./pages/SignIn"));
const ResetPasswordDetails = lazy(
  () => import("./pages/authentication/ResetPasswordDetails")
);
const VerifyEmail = lazy(() => import("./pages/authentication/VerifyEmail"));
const DocusignSignedSuccessfully = lazy(
  () => import("./pages/authentication/DocusignSignedSuccessfully")
);

// MAIN
const Dashboard = lazy(() => import("./pages/main/Dashboard"));
const TransactionsHistory = lazy(
  () => import("./pages/main/TransactionsHistory")
);
// const CompanyProfile = lazy(()=>import( "./pages/authentication/CompanyDetailsOutdated"));

// EXCHANGE PROCESS
const SouceOfFunds = lazy(() => import("./pages/TransactionFlow/SouceOfFunds"));
const BankAccount = lazy(() => import("./pages/TransactionFlow/BankAccount"));
const ConfirmBankDetails = lazy(
  () => import("./pages/TransactionFlow/ConfirmBankDetails")
);
const ConfirmTransfer = lazy(
  () => import("./pages/TransactionFlow/ConfirmTransfer")
);
const ResetPasswordMessage = lazy(
  () => import("./pages/authentication/ResetPasswordMessage")
);
const LargeCashFormSign = lazy(
  () => import("./pages/TransactionFlow/LargeCashFormSign")
);
const CompanyReview = lazy(() => import("./pages/authentication/CompanReview"));
const SuccessfulKYCSignatureRequest = lazy(
  () => import("./pages/TransactionFlow/SignatureRequestSuccess")
);
const SuccessfulTransactionsSignatureRequest = lazy(
  () => import("./pages/TransactionFlow/SignatureRequestSuccessLargeCashForm")
);
const CompanyReviewCheck = lazy(
  () => import("./pages/authentication/CompanyReviewCheck")
);
const CompanyProfile = lazy(() => import("./pages/CompanyProfile"));
const Reserver = lazy(() => import("./pages/Reserve"));
const ReserverStatus = lazy(
  () => import("./pages/company_reserve/ReserveStatus")
);

// LINK TO DOWNLOAD TEMPLATES FOR DOCUSING
const LargeCashFormDownloadTemplate = lazy(
  () => import("./pages/TransactionFlow/LargeCashFormDownloadTemplate")
);

const KYCNotSignedPage = lazy(
  () => import("./pages/authentication/KYCNotSignedPage")
);

export const ROUTES: any = {
  auth: {
    login: "",
    signup: "",
    verification: "",
    companySetup: "",
  },
  home: "/",
  cookiePolicy: "/cookie-policy",
  privacyPolicy: "/privacy-policy",
  termsConditions: "/terms-conditions",
  userGuide: "/user-guide",
  cr12Guide: "/cr12-guide",
  exchange: {
    step1: "/exchange/step-1",
    step2: "/exchange/step-2",
    step3: "/exchange/step-3",
    step4: "/exchange/step-4",
    step5: "/exchange/step-5",
  },
};

function App() {
  const [openCookiesDialog, setOpenCookiesDialog] = useState(false);

  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();

  const TRACKING_ID = process.env.REACT_APP_GTM_TAG || "G-ECCQGZCKYT";
  const build_environment = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    console.log("isConsent - Dev", isConsent);
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  const handleAcceptCookie = () => {
    // initialize google tag manager
    if (process.env.REACT_APP_GTM_TAG && build_environment === "production") {
      ReactGA.initialize(TRACKING_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  // const isMountedRef = useRef(true);

  // if (!window.location.href.includes("localhost")) {
  //   if (isMountedRef.current && window.location.protocol === "http:") {
  //     isMountedRef.current = false;
  //     window.location.href = window.location.href.replace("http:", "https:");
  //     return null;
  //   }

  //   if (!window.location.host.includes("www")) {
  //     isMountedRef.current = false;
  //     window.location.href = window.location.href.replace(
  //       window.location.host,
  //       `www.${window.location.host}`
  //     );
  //     return null;
  //   }
  // }

  return (
    <>
      <Suspense
        fallback={
          <PageLayout>
            {/* <div className="text-center text-primary">Loading...</div> */}
          </PageLayout>
        }
      >
        <ToastContainer data-cy="toast-container" />

        <CookieBannerConsent
          open={openCookiesDialog}
          setOpen={setOpenCookiesDialog}
          handleAcceptCookie={handleAcceptCookie}
          handleDeclineCookie={handleDeclineCookie}
        />

        <Routes>
          <Route element={<RoutesController />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/verify-email/:token" element={<EmailVerfication />} />

            <Route path="/sign-in/" element={<SignIn />} />
            <Route path="/verify-email/" element={<VerifyEmail />} />

            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/reset-password/" element={<ResetPassword />} />
            <Route
              path="/reset-password-message/"
              element={<ResetPasswordMessage />}
            />
            <Route path="/verification" element={<Verfication />} />
            <Route
              path="/main-user-verification"
              element={<MainUserVerfication />}
            />
            <Route
              path="/company-access-verification"
              element={<CompanyBannedVerfication />}
            />

            <Route
              path="/reset-password-link/"
              element={<ResetPasswordDetails />}
            />
          </Route>
          <Route path="/validate-otp/" element={<ValidateOtp />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/security-settings/" element={<TwoFactorAuth />} />
            <Route
              path="/two-factor-app-setup"
              element={<TwoFactorAuthApp />}
            />
            <Route
              path="/two-factor-email-setup"
              element={<TwoFactorAuthEmail />}
            />

            <Route path="/accounts" element={<Accounts />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/company-profile" element={<CompanyProfile />} />
            <Route
              path="transaction-form/:id"
              element={<LargeCashFormSign />}
            />

            <Route path="/suggest-rate" element={<SuggestRate />} />

            <Route path="/payment" element={<PaymentPage />} />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/transactions" element={<TransactionsHistory />} />

            <Route path="/reserve" element={<Reserver />} />

            <Route path="/reserve-status" element={<ReserverStatus />} />

            <Route path="/exchange/">
              <Route path={ROUTES.exchange.step1} element={<SouceOfFunds />} />
              <Route
                path={ROUTES.exchange.step2}
                element={<ConfirmBankDetails />}
              />
              <Route path={ROUTES.exchange.step3} element={<BankAccount />} />

              <Route
                path={ROUTES.exchange.step4}
                element={<ConfirmTransfer />}
              />
            </Route>
          </Route>
          <Route element={<CompanySetUpRoutes />}>
            <Route element={<CompanyCreation />}>
              <Route path="/company-setup" element={<Company />} />
            </Route>

            <Route element={<SignDocs />}>
              {/* <Route path="/kyc-not-signed" element={<KYCNotSignedPage />} /> */}
              <Route path="/company-document" element={<CompanyReview />} />
            </Route>
          </Route>
          <Route element={<OpenRoutes />}>
            <Route path="/support" element={<FAQS />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route
              path={ROUTES.privacyPolicy}
              element={<PrivacyPolicy setOpen={setOpenCookiesDialog} />}
            />
            <Route
              path={ROUTES.termsConditions}
              element={<TermsConditions />}
            />
            <Route path={ROUTES.userGuide} element={<UserGuide />} />
            <Route path={ROUTES.cr12Guide} element={<CR12Guide />} />
            <Route
              path={ROUTES.cookiePolicy}
              element={<CookiePolicy setOpen={setOpenCookiesDialog} />}
            />

            {/* <Route
          path="transactions/signed"
          element={<TransactionSignedSuccess />}
        /> */}
            <Route
              path="docusign/signed"
              element={<DocusignSignedSuccessfully />}
            />
            <Route
              path="kyc/signature-request"
              element={<SuccessfulKYCSignatureRequest />}
            />
            <Route
              path="transactions/signature-request"
              element={<SuccessfulTransactionsSignatureRequest />}
            />
            {/* <Route path="kyc/signed" element={<KYCSignedSuccessPage />} /> */}

            <Route
              path="/company-document-check"
              element={<CompanyReviewCheck />}
            />
            <Route
              path="/lcf/download"
              element={<LargeCashFormDownloadTemplate />}
            />
          </Route>

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>

                <Button
                  variant="contained"
                  onClick={() => {
                    if (authCtx.userDetails.isLoggedIn) {
                      if (
                        authCtx.userDetails.hasCompany &&
                        authCtx.companyDetails.documentsVerified
                      ) {
                        navigate("/dashboard");
                      } else {
                        navigate("/");
                      }
                    } else {
                      navigate("/");
                    }
                  }}
                  color="primary"
                  style={{ color: "white" }}
                >
                  Go Home
                </Button>
              </main>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
